<!--
File: ConvertToJson.vue
Description: converts file from CSV to JSON.
-->
<template>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>group</md-icon>
            </div>
            <h4 class="title">{{ $t('stdCols.list') }}</h4>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>{{ $t('select_file') }}</label>
                <md-file v-model="fname" @md-change="onFileUpload($event)" />
              </md-field>
            </div>
            <md-button class="md-success" native-type="submit"
              @click.native="onSaveFile"><md-icon>save</md-icon>Save</md-button>
          </md-card-header>
          <md-card-content>
            <div class="md-layout-item md-size-100 text-right">
              <md-table :value="content" v-model="content" class="md-table-global-sales">
                <md-table-row slot="md-table-row" slot-scope="{item}" md-selectable="multiple">
                  <template v-for="(data, index) in item">
                    <md-table-cell :md-label=headers.at(index)>
                      {{ data }}
                    </md-table-cell>
                  </template>
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </template>
  <script>
    import { mapState } from 'vuex'
  
    export default {
      name: 'json-converter',
      data() {
        return {
          headers: [],
          content: [],
          fname: ''
        }
      },
      created() {
      },
      methods: {
        onFileUpload(evt) {
          const file = evt[0]
          const reader = new FileReader()
          reader.onload = (res) => {
            const lines = res.target.result.split('\r\n');
            const cont = []
  
            //          console.log("Lines:", lines.length);
            //          this.content = lines.slice(0, 10);
            lines.forEach(element => {
              cont.push(element.split(';'))
            });
            this.headers = cont.shift();
            this.content = cont;
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(file);
          return 'success'
        },
        onSaveFile() {
          const data = []
          for (const element of this.content) {
            const json = Object.assign({}, element);
            let str = JSON.stringify(json)
  
            for (let i = 0; i < this.headers.length; i++) {
              const d = '"' + i.toString() + '"'
              str = str.replace(d, '"' + this.headers[i] + '"')
            }
            data.push(JSON.parse(str))
  
          }
          //        console.log(data)    
  
          let json = JSON.stringify(data);
          const blob = new Blob([json], { type: 'text/plain' })
          const e = document.createEvent('MouseEvents'),
            a = document.createElement('a');
          a.download = this.fname + ".json";
          a.href = window.URL.createObjectURL(blob);
          a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
          e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          a.dispatchEvent(e);
        }
      },
      computed: {
        ...mapState({
        }),
      },
      watch: {
      }
    }
  </script>  